@import "./common";
//Header
.ant-layout-header {
  &.header {
    height: 60px;
    background: #fff;
    padding: 0px;
    justify-content: space-between;
  }
}
.header {
  display: flex;
  padding: 0;
  background: #fff;
  height: 60px;
  border-bottom: 1px solid #e0e5ee;
  .logo {
    border-right: 1px solid #e0e5ee;
    width: 239px;
    font-family: "Helvetica";
    font-size: 30px;
    color: #5b5a5a;
    position: relative;
    text-align: right;
    padding-right: 20px;
    &::before {
      position: absolute;
      content: "";
      background-image: url(../images/logo.png);
      height: 30px;
      width: 30px;
      top: 17px;
      left: 20px;
    }
  }
  .menu {
    ul {
      list-style: none;
      display: flex;
      margin-bottom: 0;
      height: 60px;
      align-items: center;
      padding-right: 20px;
      li {
        padding-left: 10px;
        min-width: 60px;
      }
    }
    .ant-badge {
      height: 20px;
      width: 20px;
      top: 12px;
      background-image: url("../images/notification.png");
    }
    .avatar {
      border-radius: 50%;
      border: 1px solid #5b5a5a;
    }
    .ant-dropdown-link {
      color: #5b5a5a;
    }
  }
}

.ant-layout-sider {
  &.sidebar {
    width: 239px;
    border-right: 1px solid #e0e5ee;
  }
}
.ant-layout {
  .page-title {
    padding-left: 0;
    padding-right: 0;
  }
  .ant-card {
    .ant-card-body {
      padding: 0;
    }
  }
}
.search {
  .search-tab {
    border-bottom: 1px solid #e0e5ee;
    list-style: none;
    display: flex;
    align-items: center;
    height: 50px;
    padding-left: 0;
    li {
      padding: 0 20px;
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      display: flex;
      align-items: center;
      height: 100%;
      &.active {
        border-bottom: 1px solid #2a9cff;
      }
    }
  }
}
.search-form {
  display: flex;
  padding: 0 20px;
  .text-search {
    position: relative;
    display: flex;
    align-items: center;
    span {
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
}
.content {
  padding: 20px;
}

.mb-0 {
  margin-bottom: 0;
}

.page-content {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  .tabs {
    border-bottom: 1px solid #e0e5ee;
    .tab-content {
      padding: 0 20px;
    }
    .ant-tabs-nav {
      margin: 0;
    }
    .ant-tabs-top {
      border: none;
    }
    .ant-tabs-nav {
      &::before {
        border: none;
      }
    }
    .ant-tabs-tab {
      width: 120px;
      padding: 10px 20px;
      font-size: 14px;
      color: #54698d;
    }
    .ant-tabs-ink-bar {
      background: none;
    }
    .ant-tabs-tab-active {
      border-bottom: 1px solid #2a9cff;
    }
  }
  .form-search {
    padding: 8px 20px 10px 20px;
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding-top: 4px;
    }
    &__form {
      height: 38px;
    }
    &__input-search {
      flex: 1;
      .ant-input-affix-wrapper {
        height: 38px;
        box-shadow: none !important;
        border-right: none;
      }
      .ant-input-affix-wrapper-focused {
        border-color: #e0e5ee !important;
      }
      .ant-input-affix-wrapper:hover {
        border-color: #e0e5ee !important;
        border-right: none;
      }

      .ant-input-search-button {
        height: 38px;
        border-radius: 0px 5px 5px 1px !important;
        box-shadow: none;
        border-color: #e0e5ee !important;
      }
      .ant-input-search-button:hover {
        border-color: #e0e5ee !important;
        box-shadow: none;
      }

      .ant-btn {
        box-shadow: none !important;
      }
    }

    &__tag-select {
      width: 150px !important;

      .ant-select-selector {
        height: 38px !important;
        .ant-select-selection-overflow {
          display: none;
        }
      }
      .ant-select-selector:hover {
        border-color: #e0e5ee !important;
      }
    }

    &__list-tag-select {
      display: flex;
      align-items: center;
      margin-top: 10px;

      &__item {
        background: #f3f3f3;
        padding: 5px;
        border-radius: 5px;
        margin-right: 20px;
        color: #c0b4b4;
      }
      &__iconclose {
        color: #c0b4b4;
        margin-left: 8px;
      }
    }

    .ant-input-group.ant-input-group-compact {
      display: flex;
    }
    .ant-input-search-button {
      border-left: none;
      border-top-right-radius: 5px;
    }
  }
  .table-list {
    padding: 0px 20px;
    .custom-paginate {
      padding: 20px 0;
    }
    &__row {
      font-size: 14px;
      font-family: HelveticaWorld-Regular;
      color: #16325c;
      &--title {
        max-width: 300px;
        font-size: 14px;
        font-family: HelveticaWorld-Regular;
        color: #16325c;
      }
      &--center {
        text-align: center;
        display: block;
      }
      &--content {
        width: 350px;
        font-size: 14px;
        font-family: HelveticaWorld-Regular;
        color: #16325c;
      }
      &--enable {
        color: #2a9cff;
      }
      &--disable {
        margin-left: 5px;
        color: #c3b5b5;
      }
      &--column {
        font-size: 13px;
        font-family: HelveticaWorld-Regular;
        color: #54698d;
      }
    }

    .action {
      display: flex;
      &__delete {
        margin-right: 20px;
        color: #2a9cff;
        display: flex;
        align-items: center;
        &__titledelete {
          margin-left: 5px;
          color: #2a9cff;
          font-size: 13px;
          font-family: HelveticaWorld-Regular;
        }
        &__icon {
          margin-top: 2px;
          font-size: 13px;
        }
      }
      &__post {
        display: flex;
        align-items: center;
        &--inactive {
          color: #6d7278;
        }
        &--active {
          color: #2a9cff;
        }
        &--inactivestatus {
          margin-left: 5px;
          color: #c3b5b5;
        }
        &--activestatus {
          color: #2a9cff;
          margin-left: 5px;
        }
        &--title {
          color: #54698d;
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }
  }
}
.loader {
  text-align: center;
}
.ant-spin {
  display: block !important;
}
