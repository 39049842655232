.dashboard-menu {
    height: 100%;
    min-height: 90vh;
    border-right: unset;
    &__link {
        font-size: 14px;
        color: #54698d !important;
        font-family: 'HelveticaWorld-Regular';
    }
    &__icontitle {
        font-size: 14px;
        color: #54698d !important;
    }
    .ant-menu-submenu-open {
        background-color: #efefef;
    }
    .ant-menu-submenu-arrow {
        color: #2a9cff !important;
    }
    .dashboard-menu-side-link {
        padding-left: 25px !important;
    }
    &__home {
        border-right: unset;
    }
    &__submenu {
        color: #54698d !important;
    }
}
.dashboard {
    padding: 0 24px 24px;
}
.user-menu {
    width: 200px;
    margin-top: 10px !important;
}
