.page-user {
    .table-list {
        &__row {
            &--checktitle {
                font-size: 13px;
                color: #2a9cff;
                font-family: HelveticaWorld-Regular;
            }
        }
    }
}
.modal {
    width: 363px !important;
    height: 638px;
    .ant-modal-close {
        top: -20px !important;
        right: -20px !important;
    }
    .ant-modal-close-x {
        width: 40px !important;
        height: 40px !important;
        background: #fff;
        border-radius: 30px;
        line-height: 40px !important;
    }
}
