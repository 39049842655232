.user-detail {
    &__title {
        color: #16325c;
        font-size: 18px;
        font-weight: 400;
        text-transform: uppercase;
        text-align: center;
    }
    &__avatar {
        height: 100px;
        width: 100px;
        border-radius: 4px;
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
    }
    &__form {
        &__item {
            color: #54698d !important;
            font-size: 14px !important;
            font-family: HelveticaWorld-Regular;
        }
        &__item textarea {
            color: #54698d !important;
            font-size: 14px !important;
            font-family: HelveticaWorld-Regular;
        }
        .ant-select-selector {
            color: #54698d !important;
            font-size: 14px !important;
            font-family: HelveticaWorld-Regular;
        }
        .ant-input-disabled {
            background: none;
        }
        .ant-input[disabled] {
            pointer-events: none;
            background-color: unset;
        }
        .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
            background: unset;
            pointer-events: none;
        }
        .ant-mentions-disabled {
            background: unset;
            pointer-events: none;
            textarea {
                background: none;
            }
        }
    }
    &__tag{
        height: 24px !important;
        border-radius: 4px !important;
        background-color: #F4F6F9 !important;
    }
  
}


