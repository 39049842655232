.page-broker {
    .table-list {
        &__row {
            &--checktitle {
                font-size: 13px;
                color: #2a9cff;
                font-family: HelveticaWorld-Regular;
            }
            &--phonenumber {
                font-size: 13px;
                color: #2a9cff;
                font-family: HelveticaWorld-Regular;
            }
            &--disable {
                color: #baadad;
                font-size: 13px;
                font-family: HelveticaWorld-Regular;
            }
            &--enable {
                color: #2a9cff;
                cursor: pointer;
                font-size: 13px;
                font-family: HelveticaWorld-Regular;
            }
            &--title{
                max-width: 200px;
            }
        }
    }
}
.modalbroker {
    width: 735px !important;
    height: 638px;
    .ant-modal-close {
        top: -20px !important;
        right: -20px !important;
    }
    .ant-modal-close-x {
        width: 40px !important;
        height: 40px !important;
        background: #fff;
        border-radius: 30px;
        line-height: 40px !important;
    }
}
