.sms-report {
    .report-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: flex-start;
        align-content: center;
    }
    .report-col {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        align-self: auto;
        order: 0;
        width: calc(100% / 3);
        border: 1px solid #f1efef96;
        border-radius: 4px;
        margin-right: 50px;
        margin: 20px;
        box-shadow: 0px 2px 1px 1px #afadad;
        min-height: 200px;
        padding: 40px;
        text-align: center;
        &__title {
            font-weight: bold;
            color: #54698d;
            font-size: 17px;
            margin-bottom: 0;
            padding: 20px;
        }
        &__content {
            font-size: 26px;
            font-weight: bold;
            &--sent {
                color: #54698d
            }
            &--success {
                color: rgb(10, 218, 55);
            }
        }
    }
}
