.user-detail {
    &__title {
        color: #16325c;
        text-transform: uppercase;
        text-align: center;
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
    }
    &__avatar {
        height: 100px;
        width: 100px;
        border-radius: 4px;
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
    }
    &__form {
        &--ml10 {
            margin-left: 10px !important;
        }

        .info {
            display: flex;
            margin-top: 15px;
            &__name {
                width: 50%;
                margin-right: 50px;
            }
            &__phone {
                width: 50%;
            }
        }
        .image {
            margin-top: 20px;
            display: flex;
            &__container {
                display: flex;
                flex-direction: column;
                &--item {
                    width: 100%;
                    height: 90px;
                    border: 1.125px solid rgba(18, 151, 147, 0.1);
                    box-sizing: border-box;
                    border-radius: 4.5px;
                    object-fit: cover;
                }
                &--checkbox {
                    margin-top: 5px;
                    .ant-checkbox-inner {
                        border-radius: 50%;
                    }
                }
            }
        }
        .editor {
            margin-top: 15px;
            color: #54698d;
        }
        .input {
            margin-top: 15px;
            &__item {
                color: #54698d !important;
                font-size: 14px !important;
                font-family: HelveticaWorld-Regular;
                margin-top: 10px !important;
            }
        }
        &--span {
            color: #344d5b;
            font-family: "HelveticaWorld-Regular";
            position: relative;
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
        }
        &--span::before {
            position: absolute;
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 50%;
            top: 6px;
            left: -10px;
            background: #344d5b;
        }
        &__item {
            color: #54698d !important;
            font-size: 14px !important;
            font-family: HelveticaWorld-Regular;
        }
        &__itemimage {
            color: #54698d !important;
            font-size: 14px !important;
            font-family: HelveticaWorld-Regular;
            margin-top: 10px !important;
        }
        &__item textarea {
            color: #54698d !important;
            font-size: 14px !important;
            font-family: HelveticaWorld-Regular;
        }
        .ant-select-selector {
            color: #54698d !important;
            font-size: 14px !important;
            font-family: HelveticaWorld-Regular;
        }
        .ant-input-disabled {
            background: none;
        }
        .ant-input[disabled] {
            pointer-events: none;
            background-color: unset;
        }
        .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
            background: unset;
            pointer-events: none;
        }
        .ant-mentions-disabled {
            background: unset;
            pointer-events: none;
            textarea {
                background: none;
            }
        }
        .tags {
            margin-top: 10px;
            &__tag {
                height: 24px !important;
                border-radius: 4px !important;
                background-color: #f4f6f9 !important;
            }
        }
        .ant-checkbox-checked:after {
            border: none;
        }
        
    }
    .image-info {
        width: 110px;
        margin-right: 20px;
        &__title {
            font-family: "Helvetica";
            font-size: 13.5px;
            line-height: 16px;
            text-align: center;

            color: rgba(52, 77, 91, 0.5);
        }
        &__approved {
            font-family: Helvetica;
            font-size: 12px;
            line-height: 14px;
            color: #029334;
        }
        &__unapproved {
            font-family: Helvetica;
            font-size: 12px;
            line-height: 14px;
            color: #e26916;
            .ant-checkbox + span {
                padding-right: 0;
            }
        }
    }
}
