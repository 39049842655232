.info-home {
    &__container {
        padding: 10px 50px;
        display: flex;
        .user {
            width: 50%;
            display: flex;
            border: 1px solid #f1efef96;
            border-radius: 4px;
            margin-right: 50px;
            box-shadow: 0px 2px 1px 1px #afadad;
            &__total {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 15px;
                &--title {
                    font-weight: bold;
                    color: #54698d;
                    font-size: 17px;
                    margin-bottom: 0;
                }
                &--item {
                    font-size: 20px;
                    color: #00000075;
                    font-weight: bold;
                    margin-bottom: 0;
                }
                &--sub {
                    font-size: 26px;
                    &.line-user {
                        color: #2a9cff;
                    }
                    &.line-broker {
                        color: #52c41a;
                    }
                    &.line-connect {
                        color: #54698d;
                    }
                }
            }
            &__icon {
                display: flex;
                padding-right: 20px;
                img {
                    width: 50px;
                }
            }
        }
        .connect {
            width: 50%;
            border: 1px solid #f1efef96;
            display: flex;
            box-shadow: 0px 2px 1px 1px #afadad;
            border-radius: 4px;
        }
    }
    .dropdown {
        display: flex;
        padding: 20px 50px;
        flex-direction: row-reverse;
        .select-range {
            margin-left: 20px;
        }
        .ant-select {
            width: 150px;
        }
    }
    .char {
        padding: 10px 50px;
        &__selectchar {
            display: flex;
            padding: 20px 10px;
            flex-direction: row-reverse;
        }
    }
    .dashboard-menu-side-link {
        padding-left: 30px !important;
    }
}
