.page-empolyee {
  .table-list {
    padding: 0;
    .custom-paginate {
      padding: 20px;
    }
    &__row {
      &--checktitle {
        font-size: 13px;
        color: #2a9cff;
        font-family: HelveticaWorld-Regular;
      }
      &--setwidth {
        width: 120px;
        font-size: 14px;
        font-family: HelveticaWorld-Regular;
        color: #16325c;
      }
      &--action {
        width: 100px;
        font-size: 14px;
        font-family: HelveticaWorld-Regular;
        color: #16325c;
      }
    }
    .action {
      display: flex;
      justify-content: center;
      &__update {
        margin-right: 10px;
      }
    }
  }
}
.modal-user {
  .ant-modal-body {
    padding: 0px !important;
  }
  .ant-row {
    padding: 10px;
  }
}
.employee-modal {
  &__footer {
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-top: 10px;
    background: #eef1f6;
    height: 50px;
    &--item {
      color: #ff7043;
      font-size: 15px;
      font-family: HelveticaWorld-Regular;
      padding-left: 20px;
    }
    &--button {
      margin-right: 10px;
    }
    &--action {
      padding-right: 28px;
    }
  }
  &__footer-create {
    justify-content:flex-end;
    display: flex;
    align-items: center;
    margin-top: 10px;
    background: #eef1f6;
    height: 50px;
  }
}
