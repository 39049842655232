.create-news {
  padding-top: 10px;

  &__left {
    .news-container {
      display: flex;
      &__infomation {
        width: 50% !important;
        margin-right: 30px;
      }
      &__links {
        width: 100%;
      }
    }
  }
  &__box-white {
    padding: 15px;
    background-color: #fff;
    border-radius: 4px;
  }

  &__right {
    padding-left: 20px;
  }
  &__box-white {
    margin-bottom: 20px;
    // padding-bottom: 0;
  }
  &__box-status,
  &__box-avatar {
    .ant-form-item {
      margin: 0;
    }
  }
  &__box-status {
    .ant-form-item {
      margin: 0;
    }
    .ant-radio-group {
      display: block;
    }
    button {
      padding-left: 0;
      margin-top: 5px;
    }
  }
  &__btn-group {
    text-align: right;
    border-top: 1px solid #ddd;
    padding-top: 15px;
    margin-top: 10px;
    button {
      margin-left: 10px;
    }
  }
  &__preview {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  &__box-preview {
    padding-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px 10px;
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      p {
        margin-bottom: 0;
      }
      button {
        padding-right: 0;
      }
    }
  }

  .wrapper-editor {
    .public-DraftEditorPlaceholder-inner,
    .DraftEditor-editorContainer {
      padding-left: 15px;
    }
  }

  .ck.ck-content.ck-editor__editable {
    min-height: 280px;
  }

  .ant-upload-picture-card-wrapper {
    text-align: center;
    border: 1px solid #d9d9d9;
    padding: 15px 0;
  }
  .ant-upload.ant-upload-select-picture-card {
    border: unset;
  }
  .ant-form-item-label > label {
    display: flex;
    color: #16325c;
  }
  .create-news-box-tag--custom {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      margin-top: 30px;
      border: none;
      outline: none;
      padding-left: 0px;
      padding-right: 0px;
      box-shadow: none;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      box-shadow: none;
    }
    .ant-select-selection-overflow {
      .ant-select-selection-overflow-item-suffix {
        position: absolute;
        top: -40px;
        left: 0;
        width: 100%;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        .ant-select-selection-search {
          margin-left: 7px !important;
        }
      }
      
    }
    .ant-select-multiple .ant-select-selection-placeholder {
      top: -10px;
    }
  }
}

.create-news-box-tag {
  .ant-form-item-label > label {
    display: flex;
  }
  &__title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > p {
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
    > button {
      padding-right: 0;
    }
  }
}
.tags-container {
  // display: flex;
  &__custom {
    height: 25px !important;
    display: flex;
    border-radius: 5px !important;
    align-items: center;
    font-size: 13px !important;
    font-family: "HelveticaWorld-Regular";
    color: #16325c !important;
  }
}

.color-secondary {
  color: #a8b7c7;
}
