@import '../../assets/styles/common';
@import '../../assets/styles/font';
.login-page {
    .container {
        height: 100vh;
        background: url('../../assets/images/login/login-bgr.jpg');
        background-size: cover;
    }
    .logo {
        text-align: center;
        margin-bottom: 30px;
        font-family: 'HelveticaWorld-Regular';
        span {
            position: relative;
            color: #5B5A5A;
            font-size: 30px;
            text-align: center;
            &::before {
                content: "";
                background-image: url(../../assets/images/login/ico-logo.png);
                height: 29px;
                width: 21px;
                position: absolute;
                top: 10px;
                left: -30px;

            }
        }
    }
    .login-form {
        padding: 31px;
        background: #fff;
        border-radius: 4px;
        &__title {
            font-size:  18px;
            font-weight: 400;
            color:#54698D;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 20px;
        }
        .ant-input {
            min-width: 295px;
            height: 32px;
        }
        &__btn {
            background: $blue-btn;
            height: 36px;
            width: 180px;
            display: block;
            margin: 0 auto;
            span {
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;
            }
        }
    }
}