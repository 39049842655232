.custom-paginate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    &__total {
        font-family: "HelveticaWorld-Regular";
        font-size: 14px;
        color: #16325c;
    }
    &__paginate {
        display: flex;
        align-items: center;
        color: #2a9cff;
        .ant-pagination-item {
            margin-right: 0;
            border-radius: 0;
            &.first {
                padding: 0 10px;
                margin-right: 8px;
                border-radius: 4px;
            }
            &.first:hover {
                border-color: #e3dbdb;
            }
            &.last {
                margin-left: 8px;
                padding: 0 10px;
                border-radius: 4px;
            }
            &.last:hover {
                border-color: #e3dbdb;
            }
        }
        .ant-pagination-prev {
            margin-right: 0;
        }
    }
    .ant-pagination {
        border-radius: 8px;
        border: 1px solid #f2f2f2;
        .ant-btn-icon-only {
            background: white;
            border-color: white;
        }

        .ant-btn-icon-only:hover {
            background: white;
            border-color: white;
        }
        .anticon-caret-right {
            color: #4a90e2;
        }
        .anticon-caret-left {
            color: #4a90e2;
        }
        .ant-pagination-item-active {
            font-weight: 500;
            background: #f2f2f2;
            margin-top: -1px;
            height: 34px;
            a {
                color: #707172 !important;
            }
        }
        .ant-pagination-item {
            border-color: #f2f2f2;
            font-weight: 500;
            margin-top: -1px;
            height: 34px;
            a {
                color: #2a9cff;
            }
        }
        .ant-pagination-prev:hover {
            border-color: #f2f2f2;
        }
        .ant-pagination-next:hover {
            border-color: #f2f2f2;
        }
    }
}
