.page-news {
    .table-list {
        &__row {
            &--number {
                min-width: 100px;
                font-size: 14px;
                font-family: HelveticaWorld-Regular;
                color: #16325c;
            }
            &--column {
                font-size: 13px;
                font-family: HelveticaWorld-Regular;
                color: #54698d;
            }
        }
        .action {
            display: flex;
            justify-content: center;
            &__update {
                margin-left: 20px;
                font-size: 13px;
                font-family: HelveticaWorld-Regular;
            }
        }
        .image-title {
            display: flex;
            align-items: center;
            &__thumbs {
                width: 60px;
                height: 60px;
                border-radius: 8px;
            }
            &__title {
                color: #2a9cff;
                text-align: left;
                margin-left: 15px;
                font-size: 13px;
                font-family: HelveticaWorld-Regular;
            }
        }
    }
}
