.gutter-row {
    &__item {
        color: #16325c;
        font-size: 14px;
        font-family: HelveticaWorld-Regular;
        &--checkbox {
            padding-bottom: 0;
            padding: 12px;
            padding-bottom: 0 !important;
            // border-bottom: 1px solid #ddd;
            margin-bottom: 0;
        }
    }
    &__itemtitle{
        color: #16325c;
        font-size: 14px;
        font-family: HelveticaWorld-Regular;
        margin-left: 10px;
    }
    &__pass {
        display: flex;
        margin-top: 10px;
        &--item {
            padding: 0 !important;
        }
    }
    &__checked {
        .ant-checkbox-checked::after {
            border: 0px solid #fff !important;
            border-radius: 50% !important;
        }
        .ant-checkbox-inner {
            border-radius: 50%;
        }
    }
    &__container {
        padding-left: 10px;
        &.change-pass {
            border-top: 1px solid #ddd;
            padding: 10px 0;
        }
    }
    &__checkbox {
        padding-left: 0 !important;
        &--checkbox {
            margin-left: -5px !important;
            margin-bottom: 10px !important;
        }
        &--titlecheck {
            color: #54698d;
            font-size: 14px;
            font-family: HelveticaWorld-Regular;
        }
        &--title {
            color: #16325c;
            font-size: 14px;
            font-family: HelveticaWorld-Regular;
            margin-left: -5px;
        }
    }
}
.ant-form-item-required {
    flex-direction: row-reverse;
}
.ant-form-item-required::before {
    padding-bottom: 15px;
    padding-left: 5px;
}
.ant-row-space-between {
    margin-left: 0 !important;
}
