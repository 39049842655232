.page-notification {
    .table-list {
        &__row {
            &--titlenotification {
                // margin-left: 15px;
                text-align: left;
                display:flex;
            }
            &--titleactive {
                text-align: left;
                color: #2a9cff;
                font-size: 13px;
                font-family: HelveticaWorld-Regular;
            }
            &--titledisabled {
                text-align: left;
                color: #54698d;
                font-size: 13px;
                font-family: HelveticaWorld-Regular;
            }
        }
        .action {
            display: flex;
            justify-content: center;
            &__post {
                &--title {
                    color: #54698d;
                    margin-left: 5px;
                    cursor: pointer;
                }
            }
        }
    }
}
