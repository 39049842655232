.information {
    &__avarta {
        display: flex;
        justify-content: start;
        align-items: center;
    }
    .ant-image {
        margin: 10px 10px 10px 0;
        width: 100px;
        height: 100px;
        border: 1px solid #ccc;
        border-radius: 8px;
    }
}
